import { Injectable } from '@angular/core';
import { LocalStorageKeys, LocalStorageMap } from './localstorage.keys';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  public getItem<T extends LocalStorageKeys>(
    key: T,
  ): LocalStorageMap[T] | null {
    const value = localStorage.getItem(key);
    if (!value) return null;

    try {
      return JSON.parse(value) as LocalStorageMap[T];
    } catch {
      return value as LocalStorageMap[T];
    }
  }

  public setItem<T extends LocalStorageKeys>(
    key: T,
    value: LocalStorageMap[T],
  ): void {
    let stringified: string;

    if (typeof value !== 'string') {
      stringified = JSON.stringify(value);
    } else {
      stringified = value;
    }
    localStorage.setItem(key, stringified);
  }

  public removeItem(key: LocalStorageKeys): void {
    localStorage.removeItem(key);
  }
}
